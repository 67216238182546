import React, { useEffect, useRef } from "react";
import { useState } from 'react';
import "./Form.css";

export default function Form() {

    const [glasses, setGlasses] = useState(0);
    const [weight, setWeight] = useState("");
    const [gender, setGender] = useState("male");
    const [hours, setHours] = useState("");

    const [promillage, setPromillage] = useState("");

    const resultRef = useRef();
    useEffect(() => {
        scrollToResult()
      }, [promillage]);

    function incrementGlasses(event, amount) {
        event.preventDefault();
        if (glasses + amount >= 0) {
            setGlasses(glasses + amount);
        }
    }

    function handleWeightChange(event) {
        if (event.target.value >= 0) {
            setWeight(event.target.value);
        }
    }

    function handleGenderChange(event) {
        setGender(event.target.value);
    }

    function handleHoursChange(event) {
        setHours(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();
        const promillage = calculatePromillage(glasses, weight, gender, hours);
        setPromillage(promillage);
    }

    function scrollToResult() {
        resultRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    function calculatePromillage(glasses, weight, gender, hours) {
        const genderFactor = { "male": 0.72, "female": 0.61 }[gender] ?? 0.72;
        const a = 0.002 * Math.pow(weight, 2) * genderFactor;
        const promillage = (glasses * 10 - a * (hours - 0.5)) / (weight * genderFactor);
        if (promillage < 0) {
            return 0;
        }
        return promillage;
    }

    function canDrive(promillage) {
        return promillage < 0.2;
    }

    function getPromillageText(promillage) {
        if (promillage <= 0.15) {
            return "Niks aan de hand, je kunt gaan!";
        } else if (promillage <= 0.25) {
            return "Oke oke het mag nog net, maar pas op!";
        } else if (promillage <= 0.3) {
            return "Je mag niet meer rijden! Nog een halfuurtje wachten en dan mag je weer.";
        } else if (promillage <= 0.35) {
            return "Gewoon niet doen... zo simpel is het soms...";
        } else if (promillage <= 0.40) {
            return "Echte sukkels gaan nu rijden, wacht maar tot je weer nuchter bent.";
        } else if (promillage <= 0.45) {
            return "Je bent echt een bozo als je nu gaat rijden.";
        } else if (promillage <= 0.50) {
            return "Kijk als je nou geen beginnersrijbewijs had, dan kon je nu gewoon weg.";
        } else if (promillage <= 0.55) {
            return "Even wachten..... even wachten..... Piz- oh nee geen bier meer voor jou!";
        } else if (promillage <= 0.60) {
            return "Het is vast gezellig, of niet dan? Ik hoop dat je op de fiets bent!";
        } else if (promillage <= 0.65) {
            return "Als je Mark zou zijn lag je nu zonder twijfel op de grond of te slapen.";
        } else if (promillage <= 0.70) {
            return "Sanity check: heb je wel een rijbewijs? Ik denk het niet.";
        } else if (promillage <= 0.75) {
            return "Ga ff poepen en maak daarna een eitje, en dan ben je weer helemaal goed. Dat is een soort factory reset.";
        } else if (promillage <= 0.80) {
            return "Vraag aas jack in the box terug op stift elleboog op tafel. Jij mag niet rijden!";
        } else if (promillage <= 0.85) {
            return "Heb jij wel inzicht?";
        } else if (promillage <= 0.90) {
            return "Tip van flip: drink genoeg anti-kater-water straks.";
        } else if (promillage <= 0.95) {
            return "Vertel je vrienden dat ze alvast een emmer kunnen klaarzetten.";
        } else {
            return "Heb je je broek nog wel aan? Of ligt die op de trap?";
        }
    }


    return (
        <div className="px-8 py-4 w-fit lg:max-w-1/3 mx-auto">
            <form className="card" onSubmit={handleSubmit}>

                {/* Header */}
                <h1>Mag Mark nog wel rijden?</h1>
                <h2>Vul hieronder je gegevens in en kijk of je nog mag rijden.</h2>
                
                {/* Glasses */}
                <label>Aantal glazen ({glasses})</label>
                <div className="flex gap-2 items-top mt-1">
                    <button className="glasses_button" onClick={(e) => incrementGlasses(e, -1)}>-</button>
                    <button className="glasses_button" onClick={(e) => incrementGlasses(e, 1)}>+</button>
                    <p className="text-3xl">{'🍺'.repeat(glasses)}</p>
                </div>


                {/* Weight */}
                <label>Lichaamsgewicht</label>
                <input required min={20} max={300} type="number" value={weight} onChange={handleWeightChange}></input> kg

                {/* Gender */}
                <label>Geslacht</label>
                <select value={gender} onChange={handleGenderChange}>
                    <option value="male">Man</option>
                    <option value="female">Vrouw</option>
                </select>

                {/* Hours */}
                <label>Aantal uren sinds eerste glas</label>
                <input required type="number" value={hours} onChange={handleHoursChange}></input>

                <button type="submit">Berekenen!</button>

            </form>

            {promillage !== "" &&

                <div className="card my-6 max-w-full" ref={resultRef}>
                    <p className="text-3xl">{canDrive(promillage) ? "✅ Ja" : "❌ Nee"}</p>
                    <p>{getPromillageText(promillage)}</p>
                    <p className="text-sm mt-2">Gebaseerd op een promillage van <span className="font-medium">{Math.ceil(promillage * 100) / 100}‰</span></p>
                </div>

            }

        </div>
    );

}